
import { defineComponent, PropType } from 'vue';
import { CurrencyDisplay, useCurrencyInput } from 'vue-currency-input'

export default defineComponent({
	name: 'CurrencyInputCustom',

	props: {
		classStr: {
			type: String,
		},
    currency: {
      type: String,
      default: 'EUR',
    },
    inputType: {
      type: String,
      default: 'text',
    },
		isDisabled: {
			type: Boolean,
			default: false,
		},
    min: {
      type: Number as PropType<number>,
      default: 500,
    },
    max: {
      type: Number as PropType<number>,
      default: Number.POSITIVE_INFINITY,
    },
		modelValue: Number,
		name: {
			type: String,
			default: '',
		},
	},

  emits: ['input'],

	watch: {
		modelValue(value) {
			this.setValue(value);
		}
	},

	setup(props) {
		const { inputRef, formattedValue, setValue } = useCurrencyInput({
      autoDecimalDigits: false,
			currency: props.currency,
			currencyDisplay: CurrencyDisplay.hidden,
			locale: 'de-DE',
      precision: 2,
      valueRange: {
        min: props.min,
        max: props.max,
      },
		});

		return { inputRef, formattedValue, setValue }
	},

  methods: {
    inputHandler() {
      this.$emit('input', {
        value: this.modelValue,
      });
    }
  }
})
