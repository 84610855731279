import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "name", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    style: {"border":"1px solid #dcdfe6","background-color":"white"},
    ref: "inputRef",
    type: _ctx.inputType,
    name: _ctx.name,
    value: _ctx.formattedValue,
    disabled: _ctx.isDisabled,
    class: _normalizeClass([_ctx.classStr, 'py-1 pt-1']),
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputHandler && _ctx.inputHandler(...args)))
  }, null, 42, _hoisted_1))
}